import React, { useState, useCallback } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import {
  Text,
  Button,
  ButtonGroup,
  Form,
  FormLayout,
  TextField,
  InlineError,
  InlineStack,
} from '@shopify/polaris';
import GrapesLayout from './GrapesLayout';
import { useApi } from '../ApiProvider';
import { useFirebase } from '../FirebaseProvider';
import { useGrapesApi } from '../GrapesApiProvider';

function SignInError() {
  return <InlineError message="Email or password are wrong" fieldID="error" />;
}

function IsNotSellerError() {
  return <InlineError message="Your account is not connected with shop account. If it is not right please contact Grapes team (hi@grapes.com)." fieldID="error" />;
}


function LoginGrapes(props: { onSignedIn: (accessToken: string) => void, onCancel: VoidFunction }) {
  const api = useApi();
  const firebase = useFirebase();
  const grapesApi = useGrapesApi();

  const [showError, setShowError] = useState(false);
  const [showShowIsNotSellerError, setShowIsNotSellerError] = useState(false);

  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  const handleEmailChange = useCallback(
    (newValue: string) => setEmailValue(newValue),
    [setEmailValue],
  );

  const handlePasswordChange = useCallback(
    (newValue: string) => setPasswordValue(newValue),
    [setPasswordValue],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setShowError(false)
      setShowIsNotSellerError(false);
      const result = await firebase.signIn(emailValue, passwordValue);
      if (!result.signedIn) {
        setShowError(true)
        return;
      }
      const { isSeller } = await grapesApi.callFetchSellersMeShopsMain(result.idToken);
      if (!isSeller) {
        setShowIsNotSellerError(true);
        return;
      }
      props.onSignedIn(result.idToken);
    } catch(error: unknown) {
      setShowError(true);
    }
  }, [emailValue, passwordValue, setShowError, setShowIsNotSellerError, firebase, grapesApi]);

  const handleCancel = useCallback(() => {
    props.onCancel();
  }, [props]);

  return (
    <GrapesLayout>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          { showError ? <SignInError /> : null }
          { showShowIsNotSellerError ? <IsNotSellerError /> : null }
          <TextField
            label="Email"
            value={emailValue}
            onChange={handleEmailChange}
            autoComplete="off"
          />
          <TextField
            label="Password"
            type="password"
            value={passwordValue}
            onChange={handlePasswordChange}
            autoComplete="off"
          />
          <InlineStack align="end">
            <ButtonGroup>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button submit variant="primary">Sign in</Button>
            </ButtonGroup>
          </InlineStack>
        </FormLayout>
      </Form>
    </GrapesLayout>
  );
}

export default LoginGrapes;

