import React, { useState } from 'react';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import AssignWineModal from './AssignWineModal';

const PolarisProvider = (props: any) => {
  const [focusedProductId, setFocusedProductId] = useState('');
  const [focusedProductName, setFocusedProductName] = useState('');

  const onSelectedWine = () => {}; 

  return (
    <AppProvider
      i18n={{
        Polaris: {
          ResourceList: {
            sortingLabel: 'Sort by',
            defaultItemSingular: 'item',
            defaultItemPlural: 'items',
            showing: 'Showing {itemsCount} {resource}',
            Item: {
              viewItem: 'View details for {itemName}',
            },
          },
          Common: {
            checkbox: 'checkbox',
          },
        },
      }}
    >
      <div>
        { props.children }
      </div>
    </AppProvider>
  );
}
/*
      <AssignWineModal
        productId={focusedProductId}
        productName={focusedProductName}
        onSelected={onSelectedWine} />
        */


export default PolarisProvider;
