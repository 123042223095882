import React, { ReactNode } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import {
  Text,
  Button,
  ButtonGroup,
  BlockStack,
  Box,
  Bleed,
  InlineStack,
  Card,
  Form,
  FormLayout,
  TextField,
  Layout,
  InlineError,
} from '@shopify/polaris';

function GrapesLayout({ children }: { children: ReactNode }) {
  return (
    <Bleed marginInline="200" marginBlock="200">
      <div style={{
        background: 'url("/background.jpg")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '50px 50px 50px 50px',
      }}>
      <Layout>
        <Layout.Section>
            <BlockStack>
              <InlineStack align="center">
                <img src={'/logo4.png'} style={{ width: '350px', padding: '30px' }} />
              </InlineStack>
            </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            { children }
          </Card>
        </Layout.Section>
        <Layout.Section>
          <div style={{ color: 'white', textShadow: '1px 1px 2px #888' }}>
            <Text as="p" variant="bodyLg" alignment="center">
              Please log in to <a href="https://sell.grapes.com" target="_blank">sell.grapes.com</a> with the account you wish to connect to Shopify. This will enable you to synchronize your inventory and receive orders in the Shopify orders panel.
            </Text>
          </div>
        </Layout.Section>
        <Layout.Section>
          <div style={{ color: 'white', textShadow: '1px 1px 2px #888' }}>
            <Text as="p" variant="bodyLg" alignment="center">
              If you don't have account but would like to start selling wines with us, please contact us on <a href="mailto://hi@grapes.com">hi@grapes.com</a>.
            </Text>
          </div>
        </Layout.Section>
      </Layout>
      </div>
    </Bleed>
  );
}

export default GrapesLayout;

