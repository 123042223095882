import React, { useState, useCallback, createContext, useMemo, ReactNode, useContext, useEffect } from 'react';
import { AppProvider, Connected } from '@shopify/polaris';
import { useApi } from './ApiProvider';
import { useShopify } from './ShopifyAppBridgeProvider';
import { RefreshIcon } from '@shopify/polaris-icons';

enum ConnectionState {
  Loading,
  NotConnected,
  Connected,
  AppliedForConnections,
};

interface Api {
  connectionState: ConnectionState,
  isConnected: boolean,
  isLoading: boolean,
  refresh: () => Promise<void>,
  disconnect: () => Promise<void>,
};

const nullApi: Api = {
  connectionState: ConnectionState.NotConnected,
  isConnected: false,
  isLoading: false,
  refresh: () => Promise.resolve(),
  disconnect: () => Promise.resolve(),
};

const GrapesConnectionStatusContext = createContext<Api>(nullApi);

export const useGrapesConnectionStatus = () => useContext(GrapesConnectionStatusContext);

export const PlainGrapesConnectionStatusProvider = (props: { children: ReactNode, api: Partial<Api> }) => {
  const api = { ...nullApi, ...props.api, };

  return (
    <GrapesConnectionStatusContext.Provider value={api}>
      {props.children}
    </GrapesConnectionStatusContext.Provider>
  );
}

const GrapesConnectionStatusProvider = (props: { children: ReactNode }) => {
  const { fetchIdToken } = useShopify();
  const api = useApi();

  const [connectionState, setConnectionState] = useState(ConnectionState.Loading);

  const refreshConnectionStatus = useCallback((): Promise<void> => {
    setConnectionState(ConnectionState.Loading);
    return fetchIdToken()
      .then((idToken: string) => api.callConnectionsApi(idToken))
      .then(({ isConnected }) => {
        if (isConnected) {
          setConnectionState(ConnectionState.Connected);
        } else {
          setConnectionState(ConnectionState.NotConnected);
        }
      });
  }, [fetchIdToken, api, setConnectionState]);

  const disconnect = useCallback(async (): Promise<void> => {
    await api.callDisconnectApi(await fetchIdToken());
    setConnectionState(ConnectionState.NotConnected);
  }, [api, fetchIdToken]);

  const contextApi = useMemo<Api>(() => ({
    connectionState,
    isConnected: connectionState === ConnectionState.Connected,
    isLoading: connectionState === ConnectionState.Loading,
    refresh: refreshConnectionStatus,
    disconnect,
  }), [connectionState]);

  useEffect(() => {
    setConnectionState(ConnectionState.Loading);
    refreshConnectionStatus();
  }, [refreshConnectionStatus, setConnectionState]);

  return (
    <PlainGrapesConnectionStatusProvider api={contextApi}>
      {props.children}
    </PlainGrapesConnectionStatusProvider>
  );
}

export default GrapesConnectionStatusProvider;
