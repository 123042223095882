import React, { useCallback } from 'react';
import { TitleBar, Modal } from '@shopify/app-bridge-react';
import { useShopify } from '../ShopifyAppBridgeProvider';
import LoginGrapes from './LoginGrapes';
import GrapesConnect from './GrapesConnect';

const GrapesConnectModal = ({ onDone }: { onDone: VoidFunction }) => {
  const { fetchIdToken, hideModal } = useShopify();

  const handleOnDone = useCallback(() => {
    hideModal('login-grapes');
    console.log(onDone);
    onDone();
  }, [onDone, hideModal])

  return (
    <Modal id="login-grapes">
      <TitleBar title="Login Grapes">
        <button onClick={() => shopify.modal.hide('login-grapes')}>Cancel</button>
      </TitleBar>
      <GrapesConnect onDone={handleOnDone} />
    </Modal>
  );
}

export default GrapesConnectModal;

