import {
  Box,
  Grid,
  Text,
  Scrollable,
  BlockStack,
  Button,
  Thumbnail,
  TextField,
  Spinner,
} from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { TitleBar, Modal } from '@shopify/app-bridge-react';
import type GrapesWine from './GrapesWine';
import Wines from './Wines';
import { useApi } from '../ApiProvider';
import { useGrapesApi } from '../GrapesApiProvider';
import { useDebounce } from 'use-debounce';
import { uniqBy } from 'lodash';
import { useShopify } from '../ShopifyAppBridgeProvider';

const AssignWineModal = (props: {
  productId: string,
  productName: string,
  onSelected: (productId: string, grapesWineId: string) => void,
}) => {
  const { fetchIdToken, hideModal } = useShopify();
  const api = useApi();
  const grapesApi = useGrapesApi();

  const [isLoadingWines, setWinesLoading] = useState(false);
  const [wines, setWines] = useState<GrapesWine[]>([]);
  const [query, setQuery] = useState('');
  const [winesApiNextPageMark, setWinesApiNextPageMark] = useState('');
  const [hasMoreWinesToLoad, setHasMoreWinesToLoad] = useState(false);
  const [debouncedQuery] = useDebounce(query, 500); //, { maxWait: 2000 });

  const loadWines = useCallback(async (currentWines: GrapesWine[], query: string, winesApiNextPageMark: string) => {
    await fetchIdToken()
      .then((idToken: string) => api.callGrapesWinesApi(idToken, query, winesApiNextPageMark))
      .then(({ wines: newWines, nextFrom, hasMore }: { wines: GrapesWine[], nextFrom: string, hasMore: boolean }) => {
        setWines(uniqBy([...currentWines, ...newWines], (wine: GrapesWine) => wine.id));
        setWinesLoading(false);
        setWinesApiNextPageMark(nextFrom);
        setHasMoreWinesToLoad(hasMore);
      });
  }, [api, grapesApi, fetchIdToken, setWines, setWinesApiNextPageMark]);
  const loadWinesFirstPage = (query: string) => {
    setWinesApiNextPageMark('');
    loadWines([], query.trim(), '');
  }

  const loadWinesNextPage = (query: string) => {
    loadWines(wines, query.trim(), winesApiNextPageMark);
  }

  useEffect(() => {
    loadWinesFirstPage(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    setWinesLoading(true);
    setWinesApiNextPageMark('');
    setQuery(props.productName);
    loadWinesFirstPage(props.productName);
  }, [setQuery, props]);

  const updateQuery = useCallback((value: string) => {
    setWinesLoading(true);
    setWinesApiNextPageMark('');
    setQuery(value);
  }, [setQuery]);

  const onLoadMore = useCallback((): Promise<void> => {
    return loadWines(wines, query, winesApiNextPageMark);
  }, [wines, query, winesApiNextPageMark]);

  const onSelect = useCallback(async (wineId: string) => {
    await api.callAssignWineApi(await fetchIdToken(), props.productId, wineId);
    console.log(props);
    props.onSelected(props.productId, wineId);
    hideModal('assign-wine');
  }, [hideModal, api, props]);

  const onHide = useCallback(() => {
    setQuery(props.productName);
  }, [props.productName, setQuery]);

  return (
    <Modal id="assign-wine" onHide={onHide}>
      <Box padding="400">
        <TextField label="search" autoComplete="off" value={query} onChange={updateQuery} loading={isLoadingWines} />
      </Box>
      <Scrollable shadow style={{height: '300px'}} focusable>
        { isLoadingWines ? 
          <Box padding="200" align="center"><Spinner accessibilityLabel="loading wines" size="large" /></Box> : 
          <Wines wines={wines} onSelected={onSelect} onLoadMore={onLoadMore} showLoadMore={hasMoreWinesToLoad} /> }
      </Scrollable>
      <TitleBar title="Select wine">
        <button onClick={() => shopify.modal.hide('assign-wine')}>Cancel</button>
      </TitleBar>
    </Modal>
  );
}

export default AssignWineModal;

