import { Page, Layout } from '@shopify/polaris';
import React from 'react';
import { TitleBar } from '@shopify/app-bridge-react';
import ShopifyLocation from './ShopifyLocation';
import Products from './Products';
import GrapesAccountConnection from './GrapesAccountConnection';

const MainPage = (props: { onConnect: VoidFunction }) => {
  return (
     <Page>
    	<TitleBar title="grapes.com integration"></TitleBar>
      <Layout>
        <Layout.Section>
          <GrapesAccountConnection />
        </Layout.Section>
        <Layout.Section>
          <ShopifyLocation />
        </Layout.Section>
        <Layout.Section>
          <Products />
        </Layout.Section>
      </Layout>
     </Page>
  );
}

export default MainPage;
