import React, { createContext, useContext, ReactNode } from 'react';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// TODO: move it to some config provider or other place (maybe param)
const firebaseConfig = JSON.parse(String(process.env.REACT_APP_FIREBASE_CONFIG));

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

interface FirebaseApi {
  signIn: (email: string, password: string) => Promise<{ signedIn: boolean, idToken: string }>;
}

const FirebaseApiContext = createContext<FirebaseApi>({
  signIn: (email: string, password: string) => Promise.reject(),
});

export const useFirebase = () => useContext(FirebaseApiContext);

const FirebaseProvider = (props: { children: ReactNode }) => {
  const api = {
    signIn: async (email: string, password: string): Promise<{ signedIn: boolean, idToken: string }> => {
      try {
        const auth = getAuth();
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const idToken = await user.getIdToken();
        return { signedIn: true, idToken };
      } catch(err) {
        console.error(err);
        return { signedIn: false, idToken: '' };
      }
    },
  };

  return (
    <FirebaseApiContext.Provider value={api}>
      { props.children }
    </FirebaseApiContext.Provider>
  );
}

export default FirebaseProvider;
