import {
  AppProvider,
  Card,
  Page,
  LegacyCard,
  ResourceList,
  Avatar,
  Text,
  Link,
  AccountConnection,
  Layout,
  Spinner,
  SkeletonBodyText,
} from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { TitleBar, useAppBridge, ShopifyGlobal } from '@shopify/app-bridge-react';
import ShopifyLocation from './ShopifyLocation';
import Products from './Products';
import { useApi } from './ApiProvider';
import { useShopify } from './ShopifyAppBridgeProvider';
import { useGrapesConnectModal } from './GrapesConnect';
import { useGrapesConnectionStatus } from './GrapesConnectionStatusProvider';

const fetchIdToken = async (shopify: ShopifyGlobal): Promise<string> => {
  await shopify.ready;
  const idToken = await shopify.idToken();
  return idToken;
}

const GrapesAccountConnection = (props: {}) => {
  const { fetchIdToken } = useShopify();
  const api = useApi();
  const { showModal: showGrapesConnectModal } = useGrapesConnectModal();
  const { isConnected, isLoading, refresh: refreshConnectionStatus, disconnect } = useGrapesConnectionStatus();
  
  const handleAction = useCallback(async () => {
     if (isConnected) {
       await disconnect();
     } else  {
       showGrapesConnectModal(refreshConnectionStatus);
    }
  }, [api, isConnected, fetchIdToken]);

  if (isLoading) {
    return (
      <Card>
        <SkeletonBodyText />
      </Card>
    );
  }

  if (isConnected) {
    return (
      <AccountConnection
        avatarUrl={'/logo2.png'}
        accountName={''} // TODO: shop name?
        connected={true}
        title="grapes.com"
        action={{
          content: 'Disconnect',
          onAction: handleAction,
        }}
        details={(
          <p>
            Account connected
            <br/>
            <br/>
            Check your account on <Link url="https://sell.grapes.com" external={true}>sell.grapes.com</Link>
          </p>
        )}
        termsOfService={null}
      />
    );
  }

  return (
    <AccountConnection
      avatarUrl={'/logo2.png'}
      accountName={''}
      connected={isConnected}
      title="grapes.com"
      action={{
        content: 'Connect',
        onAction: handleAction,
      }}
      details={'No account connected'}
      termsOfService={(
        <p>
          By clicking <strong>Connect</strong>, you agree to accept  App’s{' '}
          <Link url="https://grapes.com/privacy-policy" external={true}>terms and conditions</Link>. 
        </p>
      )}
    />
  );
}

export default GrapesAccountConnection;
