import {
  Button,
  Thumbnail,
  Text,
  Tooltip,
  IndexTable,
  InlineStack,
  SkeletonThumbnail,
} from '@shopify/polaris';
import React, { useCallback, ReactNode } from 'react';
import AssignedWine from './AssignedWine';
import type ProductData from './ProductData';

const ConnectionRequiredTooltip = ({ enabled, children }: { enabled: boolean, children: ReactNode }) => {
  if (!enabled) {
    return <div>{children}</div>;
  }
  return (
    <Tooltip dismissOnMouseOut content="Connect account to update assignment">
      {children}
    </Tooltip>
  ); 
} 

const TableRow = ({
  position,
  product,
  isUpdateEnabled,
  onClickUpdate,
}: {
  position: number,
  product: ProductData,
  isUpdateEnabled: boolean,
  onClickUpdate: (id: string) => void,
}) => {
  const onUpdate = useCallback(() => {
    onClickUpdate(product.id);
  }, [product, onClickUpdate]);

  return (
    <IndexTable.Row
        id={product.id}
        key={product.id}
        selected={false}
        position={position}
      >
        <IndexTable.Cell>
          <InlineStack blockAlign="center" gap="400">
            { product.featuredImageUrl === 'undefined' ? 
              <SkeletonThumbnail size="large" /> :
              <Thumbnail
                size="large"
                source={product.featuredImageUrl}
                alt="Black choker necklace"
              />
            }
            <Text variant="bodyMd" fontWeight="bold" as="span">{ product.title }</Text>
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <AssignedWine assignedGrapesWine={product.assignedGrapesWine} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <ConnectionRequiredTooltip enabled={!isUpdateEnabled}>
            <Button onClick={onUpdate} disabled={!isUpdateEnabled}>Update</Button>
           </ConnectionRequiredTooltip>
        </IndexTable.Cell>
      </IndexTable.Row>
  );
}

export default TableRow;
