import {
  BlockStack,
  Card,
  IndexTable,
  Spinner,
  Text,
} from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { useAppBridge, ShopifyGlobal } from '@shopify/app-bridge-react';
import ProductData from './ProductData';
import { useAssignWineModal } from '../AssignWineModal';
import TableRow from './TableRow';
import BannerSyncWarning from './BannerSyncWarning';
import BannerConnectAccount from './BannerConnectAccount';
import { useApi } from '../ApiProvider';
import { useShopify } from '../ShopifyAppBridgeProvider';
import { useGrapesConnectionStatus } from '../GrapesConnectionStatusProvider';

const Products = () => {
  const { fetchIdToken } = useShopify();
  const { showModal } = useAssignWineModal();
  const api = useApi();
  const { isConnected: isAccountConnectedWithGrapes } = useGrapesConnectionStatus();

  const [isLoading, setIsLoading] = useState(true);
  const [isProductsSyncInProgress, setIsProductsSyncInProgress] = useState(false);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [focusedProductId, setFocusedProductId] = useState('');

  const refreshProducts = useCallback(async () => {
    const { inSync, products } = await api.callProductsApi(await fetchIdToken());
    setIsProductsSyncInProgress(inSync);
    setProducts(products);
  }, [api, fetchIdToken, setProducts]);

  useEffect(() => {
    setIsLoading(true);
    refreshProducts()
      .then(() => setIsLoading(false));
  }, [refreshProducts, setIsLoading]);

  const onSelectedWine = useCallback(async (productId: string, wineId: string) => {
    await refreshProducts();
  }, [refreshProducts]);

  const productName = useCallback((productId: string) => {
    const product = products.find((p: ProductData) => p.id === productId);
    if (!product) {
      return '';
    }
    return product.title;
  }, [products]);

  const onUpdate = useCallback((productId: string) => {
    setFocusedProductId(productId);
    showModal(productId, productName(productId), onSelectedWine);
  }, [showModal, productName, setFocusedProductId, onSelectedWine]);

  const onRefresh = useCallback(() => {
    setIsLoading(true);
    refreshProducts()
      .then(() => setIsLoading(false));
  }, [setIsLoading, refreshProducts]);
 
  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };

  const focusedProductName = (): string => {
    const product = products.find((product: ProductData) => product.id === focusedProductId);
    if (!product) {
      return '';
    }
    return product.title;
  }

  if (isLoading) {
    return (
      <div data-testid="loader">
        <Card>
          <Text as="h2" variant="headingSm">
            Products mapping
          </Text>
          <BlockStack inlineAlign="center">
            <Spinner accessibilityLabel="loading products" size="large" />
          </BlockStack>
        </Card>
      </div>
    );
  }

  return (
    <Card>
      <BlockStack gap="800">
        <BlockStack gap="200">
          <Text as="h2" variant="headingSm">
            Products mapping
          </Text>
          <Text as="p" variant="bodyMd">
            Please assign your products to our wine database. 
            This will enable us to sell your wines on our portal.
          </Text>
        </BlockStack>
        { isAccountConnectedWithGrapes ? null : <BannerConnectAccount /> }
        { isProductsSyncInProgress ? <BannerSyncWarning onRefresh={onRefresh} /> : null }
        <IndexTable
          resourceName={resourceName}
          itemCount={1}
          selectable={false}
          headings={[
            {title: 'Shopify product'},
            {title: 'Assigned Grapes wine'},
            {title: ''},
          ]}
        >
          { products.map((p: ProductData, i: number) => <TableRow key={p.id} position={i + 1} product={p} isUpdateEnabled={isAccountConnectedWithGrapes} onClickUpdate={onUpdate}/>)}
        </IndexTable>
      </BlockStack>
    </Card>
  );
}

export default Products;
