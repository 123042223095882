import { Banner, Text } from '@shopify/polaris';

const BannerConnectAccount = () => {
  return (
    <div data-testid="connect-account-alert">
      <Banner tone="warning">
        <Text as="h2" variant="headingSm">
          Connect account
        </Text>
        <Text as="p" variant="bodyMd">
          To map your wines, please connect your account.
        </Text>
      </Banner>
    </div>
  );
}

export default BannerConnectAccount;
