import {
  Box,
  BlockStack, 
  Button,
  ResourceList,
  ResourceItem,
  Text,
  SkeletonThumbnail,
  Thumbnail,
} from '@shopify/polaris';
import type GrapesWine from './GrapesWine';
import Wine from './Wine';
import React, { useCallback, useState } from 'react';

interface Props {
  wines: GrapesWine[],
  showLoadMore: boolean,
  onSelected: (grapesWineId: string) => void,
  onLoadMore: () => Promise<void>,
};

const WineImage = (props: { wine: GrapesWine}) => {
  if (props.wine.imageUrl === 'undefined' || props.wine.imageUrl === '') {
    return <SkeletonThumbnail size="medium" />;
  }
  return (
    <Thumbnail
      size="medium"
      source={props.wine.imageUrl}
      alt="Black choker necklace"/>
  );
}

const Wines = ({ wines, showLoadMore, onSelected, onLoadMore }: Props) => {

  const [loadingMore, setLoadingMore] = useState(false); 

  const handleMore = useCallback(() => {
    setLoadingMore(true);
    onLoadMore().then(() => setLoadingMore(false));
  }, [onLoadMore, setLoadingMore])

  return (
    <BlockStack gap="400">
      <ResourceList
        resourceName={{ singular: 'wine', plural: 'wines' }}
        items={wines}
        renderItem={(wine: GrapesWine) => {
          return (
            <ResourceItem
              id={wine.id}
              name={wine.name}
              onClick={() => onSelected(wine.id)}
              media={<WineImage wine={wine} />}
              >
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                {wine.name}
              </Text>
              <Text variant="bodyMd" as="p">
                color: {wine.color}
              </Text>
              <Text variant="bodyMd" as="p">
                producer: {wine.producerName}
              </Text>
            </ResourceItem>
          )
        }}>
      </ResourceList>
      { showLoadMore ? <Box padding="200" align="center"><Button loading={loadingMore} onClick={handleMore}>load more.</Button></Box> : null }
    </BlockStack>
  );
}

export default Wines;
