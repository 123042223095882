import {
  InlineStack,
  Thumbnail,
  SkeletonThumbnail,
  Badge,
  Text,
} from '@shopify/polaris';
import React from 'react';
import type AssignedGrapesWine from './AssignedGrapesWine';

const AssignedWine = (props: { assignedGrapesWine: AssignedGrapesWine }) => {
  if (!props.assignedGrapesWine.assigned) {
    return (
      <Badge progress="incomplete" tone="attention">not matched yet</Badge>
    );
  }
  return (
     <InlineStack blockAlign="center" gap="400">
      { props.assignedGrapesWine.imageUrl === '' ? 
        <SkeletonThumbnail size="large" /> :
        <Thumbnail
          size="large"
          source={props.assignedGrapesWine.imageUrl}
          alt="Black choker necklace"
        />
      }
      <Text variant="bodyMd" fontWeight="bold" as="span">{ props.assignedGrapesWine.name }</Text>
    </InlineStack>
  );
}

export default AssignedWine;

