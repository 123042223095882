import React, { useState, useCallback, createContext, useMemo, ReactNode, useContext } from 'react';
import { AppProvider } from '@shopify/polaris';
import GrapesConnectModal from './GrapesConnectModal';
import { useShopify } from '../ShopifyAppBridgeProvider';

interface Api {
  showModal: (onDone: VoidFunction) => void;
  hideModal: VoidFunction;
}

const nullApi = {
  showModal: (onDone: VoidFunction) => {},
  hideModal: () => {},
};

const GrapesConnectModalContext = createContext<Api>(nullApi);

export const useGrapesConnectModal = () => useContext(GrapesConnectModalContext);

export const PlainGrapesConnectModalProvider = (props: { children: ReactNode, api: Partial<Api> }) => {
  const api = { ...nullApi, ...props.api, };

  return (
    <GrapesConnectModalContext.Provider value={api}>
      {props.children}
    </GrapesConnectModalContext.Provider>
  );
}

const GrapesConnectModalProvider = (props: { children: ReactNode }) => {
  const { showModal, hideModal: hideShopifyModal } = useShopify();

  const [onDone, setOnDone] = useState<VoidFunction>(() => {});

  const api = useMemo(() => ({
    showModal: (onDoneCallback: VoidFunction) => {
      setOnDone(() => onDoneCallback);
      showModal('login-grapes');
    },
    hideModal: () => {
      hideShopifyModal('login-grapes');
    }
  }), [showModal, hideShopifyModal, setOnDone])

  return (
    <PlainGrapesConnectModalProvider api={api}>
      {props.children}
      <GrapesConnectModal onDone={onDone} />
    </PlainGrapesConnectModalProvider>
  );
}

export default GrapesConnectModalProvider;
