import React, { useState, useCallback } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import {
  Text,
  Button,
  ButtonGroup,
  BlockStack,
  InlineStack,
  Card,
  Form,
  FormLayout,
  TextField,
  Layout,
  InlineError,
} from '@shopify/polaris';
import { useApi } from '../ApiProvider';
import { useFirebase } from '../FirebaseProvider';
import { useGrapesApi } from '../GrapesApiProvider';

function SignInError() {
  return <InlineError message="Email or password are wrong" fieldID="error" />;
}

function IsNotSellerError() {
  return <InlineError message="Your account is not connected with shop account. If it is not right please contact Grapes team (hi@grapes.com)." fieldID="error" />;
}


function SendApplication() {
  return (
    <Layout>
      <Layout.Section>
          <BlockStack>
            <InlineStack align="center">
              <img src={'/logo2.png'} width="100px" height="100px" style={{ margin: 'auto 0;' }}/>
            </InlineStack>
          </BlockStack>
      </Layout.Section>
      <Layout.Section>
        <Card>
        </Card>
      </Layout.Section>
      <Layout.Section>
        <Text as="p" variant="bodyLg" alignment="center">
          Please log in to <a href="https://sell.grapes.com" target="_blank">sell.grapes.com</a> with the account you wish to connect to Shopify. This will enable you to synchronize your inventory and receive orders in the Shopify orders panel.
        </Text>
      </Layout.Section>
      <Layout.Section>
        <Text as="p" variant="bodyLg" alignment="center">
          If you don't have account but would like to start selling wines with us, please contact us on <a href="mailto://hi@grapes.com">hi@grapes.com</a>.
        </Text>
      </Layout.Section>
    </Layout>
  );
}

export default SendApplication;

