import React, { useState, createContext, ReactNode, useContext, useMemo } from 'react';
import { array, boolean, object, string, number, date, InferType } from 'yup';
import { useAppBridge } from '@shopify/app-bridge-react';
import { PlainAssignWineModalProvider } from './AssignWineModal';

interface Location {
  id: string;
  name: string;
}

export interface ShopifyApi {
  fetchIdToken: () => Promise<string>;
  fetchLocations: () => Promise<Location[]>;
  showModal: (id: string) => void;
  hideModal: (id: string) => void;
}

const nullApi: ShopifyApi = {
  fetchIdToken: () => Promise.reject(new Error('not set')),
  fetchLocations: () => Promise.reject(new Error('not set')),
  showModal: (id: string) => { throw new Error('not set') },
  hideModal: (id: string) => { throw new Error('not set') },
}

const fetchLocations = async (): Promise<Location[]> => {
  const res = await fetch('shopify:admin/api/graphql.json', {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query GetLocations($first: Int!) {
          locations(first: $first) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables: { first: 10 },
    }),
  });
  const { data } = await res.json();
  return data.locations.edges.map((edge: any) => ({ id: String(edge.node.id), name: String(edge.node.name) }));
}

const ShopifyAppBridgeContext = createContext<ShopifyApi>(nullApi);

export const useShopify = () => useContext(ShopifyAppBridgeContext);

export function PlainShopifyAppBridgeProvider(props: { children: ReactNode, api: Partial<ShopifyApi> }) {
  const api = useMemo(() => ({ ...nullApi, ...props.api, }), [props]);
  return (
    <ShopifyAppBridgeContext.Provider value={api}>
      { props.children }
    </ShopifyAppBridgeContext.Provider>
  );
}

function ShopifyAppBridgeProvider(props: { children: ReactNode }) {
  const shopify = useAppBridge();

  const api = useMemo(() => ({
    fetchIdToken: async () => {
      await shopify.ready;
      const idToken = await shopify.idToken();
      await fetchLocations();
      return idToken;
    },
    fetchLocations,
    showModal: (id: string) => {
      shopify.modal.show(id);
    },
    hideModal: (id: string) => {
      shopify.modal.hide(id);
    },
  }), [shopify]);

  return (
    <PlainShopifyAppBridgeProvider api={api}>
      { props.children }
    </PlainShopifyAppBridgeProvider>
  );
}

export default ShopifyAppBridgeProvider;
