import { array, boolean, object, string, number, date, InferType } from 'yup';

const serverUrl = process.env.REACT_APP_BACKEND_URL + '/api';

class ApiError extends Error {

}

const verifyResponseContentTypeIsJson = async (result: Response) => {
  if ((new RegExp('application/json').test(String(result.headers.get('Content-Type'))))) {
    return;
  }
  throw new ApiError(`response content is not json: ${await result.text()}`)
}

export const callConnectionsApiUsingGrapesAccessTokeb = async (accessToken: string): Promise<{ isConnected: boolean }> => {
  const result = await fetch(serverUrl + '/connections/mine', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
    }
  });
  const json = await result.json();
  return { isConnected: !!json.isConnected };
}

export const callConnectionsApi = async (idToken: string): Promise<{ isConnected: boolean }> => {
  const result = await fetch(serverUrl + '/connections/mine', {
    method: 'GET',
    headers: {
      'X-Shoppify-Id-Token': idToken,
      'Accept': 'application/json',
    }
  });
  await verifyResponseContentTypeIsJson(result);
  const json = await result.json();
  return { isConnected: !!json.isConnected };
}

export const callConnectApi = async (accessToken: string, shopifyToken: string, grapesShopId: string): Promise<void> => {
  const result = await fetch(serverUrl + '/connections', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      shopifyToken,
      grapesShopId,
    })
  });
  if (result.status !== 200) {
    throw new ApiError('something went wrong');
  }
  return;
}

export const callDisconnectApi = async (idToken: string): Promise<void> => {
  const result = await fetch(serverUrl + '/connections', {
    method: 'DELETE',
    headers: {
      'X-Shoppify-Id-Token': idToken,
    }
  });
  if (result.status !== 200) {
    throw new ApiError('something went wrong');
  }
  return;
}

export const callInstalledApi = async (idToken: string): Promise<void> => {
  const result = await fetch(serverUrl + '/installed', {
    method: 'POST',
    headers: {
      'X-Shoppify-Id-Token': idToken,
    }
  });
  if (result.status !== 200) {
    throw new ApiError('POST /installed: something went wrong');
  }
  return;
}

export interface Product {
  id: string;
  title: string;
  featuredImageUrl: string;
  assignmentStatus: string;
  assignedGrapesWine: {
    assigned: boolean;
    id: string;
    name: string;
    imageUrl: string;
  }
}

const productsApiResponseProduct = object().required().shape({
  id: string().nonNullable().defined(),
  title: string().nonNullable().defined(),
  featuredImageUrl: string().nonNullable().defined(),
  grapesWineAssignementStatus: string().nonNullable().defined(),
  assignedGrapesWine: object().required().shape({
    assigned: boolean().required(),
    id: string().nonNullable().defined(),
    name: string().nonNullable().defined(),
    imageUrl: string().nonNullable().defined(),
  }),
});

type ProductsApiResponseProduct = InferType<typeof productsApiResponseProduct>;

const productsApiResponse = object().required().shape({
  sync: object().required().shape({
    inProgress: boolean().required(),
    progress: number().required(),
  }),
  products: array().required().of(productsApiResponseProduct),
})

export const callProductsApi = async (idToken: string): Promise<{ inSync: boolean, products: Product[] }> => {
  const result = await fetch(serverUrl + '/products', {
    method: 'GET',
    headers: {
      'X-Shoppify-Id-Token': idToken,
    }
  });
  if (result.status !== 200) {
    throw new ApiError('something went wrong');
  }
  const json = await result.json();
  const parsedJson = productsApiResponse.validateSync(json);
  const products = parsedJson.products.map((p: ProductsApiResponseProduct) => ({
    id: p.id,
    title: p.title,
    featuredImageUrl: p.featuredImageUrl,
    assignmentStatus: p.grapesWineAssignementStatus,
    assignedGrapesWine: {
      assigned: p.assignedGrapesWine.assigned,
      id: p.assignedGrapesWine.id,
      name: p.assignedGrapesWine.name,
      imageUrl: p.assignedGrapesWine.imageUrl,
    }
  }));
  return {
    inSync: json.sync.inProgress,
    products,
  };
}

const grapesWineApiResponseWine = object().required().shape({
  id: string().nonNullable().defined(),
  name: string().nonNullable().defined(),
  color: string().nonNullable().defined(),
  imageUrl: string().nonNullable().defined(),
  producerName: string().nonNullable().defined(),
});

type ApiGrapesWine = InferType<typeof grapesWineApiResponseWine>;

const grapesWineApiResponse = object().required().shape({
  wines: array().required().of(grapesWineApiResponseWine),
  hasMore: boolean().required(),
  nextFrom: string().nonNullable().defined(),
});

export const callGrapesWinesApi = async (idToken: string, query: string, nextFrom: string): Promise<{ wines: ApiGrapesWine[], hasMore: boolean, nextFrom: string }> => {
  const params = { query, nextFrom };
  const result = await fetch(serverUrl + '/grapes-wines?' + ( new URLSearchParams( params ) ).toString(), {
    method: 'GET',
    headers: {
      'X-Shoppify-Id-Token': idToken,
    },
  });
  if (result.status !== 200) {
    throw new ApiError(`something went wrong: ${result.status} ${await result.text()}`);
  }
  const json = await result.json();
  return await grapesWineApiResponse.validate(json);
}

export const callAssignWineApi = async (idToken: string, shopifyProductId: string, grapesWineId: string): Promise<void> => {
  const result = await fetch(serverUrl + '/assign-grapes-wine', {
    method: 'POST',
    headers: {
      'X-Shoppify-Id-Token': idToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ shopifyProductId, grapesWineId }),
  });
  if (result.status !== 200) {
    throw new ApiError('something went wrong');
  }
}

const assignedShopifyLocationApiResponse = object().required().shape({
  noLocationAssigned: boolean().nonNullable().defined(),
  locationId: string().nonNullable().defined(),
});

export const callFetchAssignedShopifyLocationApi = async (idToken: string): Promise<{ noLocationAssigned: boolean, locationId: string }> => {
  const result = await fetch(serverUrl + '/assigned-shopify-location', {
    method: 'GET',
    headers: {
      'X-Shoppify-Id-Token': idToken,
    },
  });
  if (result.status !== 200) {
    throw new ApiError('something went wrong');
  }
  const json = await result.json();
  return await assignedShopifyLocationApiResponse.validate(json);
}

export const callChangeAssignedShopifyLocationApi = async (idToken: string, shopifyLocationId: string): Promise<void> => {
  const result = await fetch(serverUrl + '/assigned-shopify-location', {
    method: 'POST',
    headers: {
      'X-Shoppify-Id-Token': idToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ shopifyLocationId }),
  });
  if (result.status !== 200) {
    throw new ApiError('something went wrong');
  }
}
