import {
  AppProvider,
  Box,
  Card,
  Page,
  LegacyCard,
  ResourceList,
  Avatar,
  Text,
  Link,
  AccountConnection,
  Layout,
  Spinner,
  Select,
  Icon,
} from '@shopify/polaris';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { TitleBar, useAppBridge, ShopifyGlobal } from '@shopify/app-bridge-react';
import Products from './Products';
import { useApi } from './ApiProvider';
import { useShopify } from './ShopifyAppBridgeProvider';
import { LocationIcon } from '@shopify/polaris-icons';

interface Location {
  id: string;
  name: string;
}

const ShopifyLocation = (props: any) => {
  const { fetchIdToken, fetchLocations } = useShopify();
  const {
    callFetchAssignedShopifyLocationApi,
    callChangeAssignedShopifyLocationApi,
  } = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchLocations().then((locations: Location[]) => setLocations(locations)),
      fetchIdToken().then(callFetchAssignedShopifyLocationApi).then(({ locationId, noLocationAssigned }: { locationId: string, noLocationAssigned: boolean }) => {
        if (noLocationAssigned) {
          setSelectedLocation('');
        } else {
          setSelectedLocation(locationId);
        }
      }),
    ]).then(() => setIsLoading(false));
  }, [setLocations, fetchLocations, setIsLoading, fetchIdToken, callFetchAssignedShopifyLocationApi]);

  const selectOptions = useMemo(() => {
    if (isLoading) {
      return [{ label: 'loading...', value: '' }];
    }
    const options = locations.map((location: Location) => ({
      label: location.name,
      value: location.id,
    }));
    return [{ label: 'No location selected', value: '' }, ...options];
  }, [isLoading, locations]);

  const onLocationChanged = useCallback((id: string) => {
    setIsSaving(true);
    setSelectedLocation(id);
    fetchIdToken()
      .then((token: string) => callChangeAssignedShopifyLocationApi(token, id))
      .then(() => setIsSaving(false));
  }, [setSelectedLocation]);

  return (
    <Card>
      <Text as="h2" variant="headingSm">
        Location
      </Text>
      <Box paddingBlockStart="200">
        <Text as="p" variant="bodyMd">
          Select the location you want to assign to your Grapes account.
          We will use the location inventory to sync with it. 
        </Text>
      </Box>
      <Box paddingBlockStart="200">
        <Select label="" options={selectOptions} value={selectedLocation} onChange={onLocationChanged } disabled={isLoading || isSaving}/>
      </Box>
    </Card>
  );
}

export default ShopifyLocation;
