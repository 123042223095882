import React, { useState, useCallback, useEffect } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import {
  Text,
  Button,
  BlockStack,
  ButtonGroup,
  Card,
  Layout,
  InlineStack,
} from '@shopify/polaris';
import GrapesLayout from './GrapesLayout';
import { useAppBridge, ShopifyGlobal } from '@shopify/app-bridge-react';
import { useApi } from '../ApiProvider';
import { useGrapesApi } from '../GrapesApiProvider';

const fetchIdToken = async (shopify: ShopifyGlobal): Promise<string> => {
  await shopify.ready;
  const idToken = await shopify.idToken();
  return idToken;
}

function Confirmation({ accessToken, onConnected, onCancel }: { accessToken: string, onConnected: VoidFunction, onCancel: VoidFunction }) {
  const shopify = useAppBridge();
  const api = useApi();
  const grapesApi = useGrapesApi();

  const [isLoading, setIsLoading] = useState(false);
  const [shopId, setShopId] = useState('');
  const [shopName, setShopName] = useState('');

  useEffect(() => {
    setIsLoading(true);
    grapesApi.callFetchSellersMeShopsMain(accessToken)
      .then((data: { id: string, shopName: string }) => {
        setShopId(data.id);
        setShopName(data.shopName);
        setIsLoading(false);
      });
  }, [grapesApi, accessToken, setShopId, setShopName, setIsLoading])

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConnect = useCallback(async () => {
    const idToken = await fetchIdToken(shopify);
    await api.callConnectApi(accessToken, idToken, shopId)
    onConnected();
  }, [accessToken, api, shopify, shopId, onConnected]);

  if (isLoading) {
    return (
      <Layout>
        <Layout.Section>
          <Card>
            Loading...
          </Card>
        </Layout.Section>
      </Layout>
    );
  }

  return (
    <GrapesLayout>
      <BlockStack gap="200">
        <Text as="h2" variant="headingSm">
          Connection confirmations
        </Text>
        <Text as="h2" variant="bodyMd">
          You are about to connect your Shopify account with the Grapes account named:
          <br/>
          <strong>{ shopName }</strong>.
          <br/>
          <br/>
          Please confirm if this is correct.
        </Text>
        <InlineStack align="end">
          <ButtonGroup>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleConnect} variant="primary">Yes</Button>
          </ButtonGroup>
        </InlineStack>
      </BlockStack>
    </GrapesLayout>
  );
}

export default Confirmation;

