import React, { useState, createContext, ReactNode, useContext } from 'react';
import { array, boolean, object, string, number, date, InferType } from 'yup';

interface Api {
  callFetchSellersMeShopsMain: (accessToken: string) => Promise<{ isSeller: boolean, id: string, shopName: string }>;
}

const sellersMeShopsMainResponse = object().required().shape({
  id: string().required(),
  name: string().nonNullable().defined(),
});

class GrapesApiError extends Error {
  
}

const urlBase = String(process.env.REACT_APP_GRAPES_API_URL_BASE);

export const callFetchSellersMeShopsMain = async (accessToken: string): Promise<{ isSeller: boolean, id: string, shopName: string }> => {
  const result = await fetch(urlBase + '/sellers/me/shops/main', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Accept': 'appliction/json; charset=utf-8'
    },
  });
  if (result.status === 403) {
    return { isSeller: false, id: '', shopName: '' };

  } else if (result.status !== 200) {
    throw new GrapesApiError('something went wrong');
  }
  const json = await result.json();
  const validatedJson = await sellersMeShopsMainResponse.validate(json);
  return {
    isSeller: true,
    id: validatedJson.id,
    shopName: validatedJson.name
  };
}

const GrapesApiContext = createContext<Api>({
  callFetchSellersMeShopsMain,
});

export const useGrapesApi = () => useContext(GrapesApiContext);

function GrapesApiProvider(props: { children: ReactNode }) {
  const [api] = useState<Api>({
    callFetchSellersMeShopsMain,
  });

  return (
    <GrapesApiContext.Provider value={api}>
      { props.children }
    </GrapesApiContext.Provider>
  );
}

export default GrapesApiProvider;
