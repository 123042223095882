import { Box, FooterHelp, Link, InlineStack } from '@shopify/polaris';

const Footer = () => {
  return (
    <FooterHelp>
      <Box padding="200">
        <InlineStack gap="200">
          <Link external={true} url="http://grapes.com">grapes.com</Link> 
          <Link external={true} url="http://sell.grapes.com">sell.grapes.com</Link> 
          <Link external={true} url="mailto://hi@grapes.com">hi@grapes.com</Link> 
        </InlineStack>
      </Box>
    </FooterHelp>
  );
}

export default Footer;
