import React, { useCallback, useState, useEffect } from 'react';
import PolarisProvider from './PolarisProvider';
import MainPage from './MainPage';
import GrapesConnect from './GrapesConnect';
import ApiProvider from './ApiProvider';
import GrapesApiProvider from './GrapesApiProvider';
import FirebaseProvider from './FirebaseProvider';
import ShopifyAppBridgeProvider from './ShopifyAppBridgeProvider';
import GrapesConnectModalProvider from './GrapesConnect';
import GrapesConnectionStatusProvider from './GrapesConnectionStatusProvider';
import Footer from './Footer';
import { AssignWineModalProvider } from './AssignWineModal';
import { useAppBridge, ShopifyGlobal } from '@shopify/app-bridge-react';
import { useApi } from './ApiProvider';

const fetchIdToken = async (shopify: ShopifyGlobal): Promise<string> => {
  await shopify.ready;
  const idToken = await shopify.idToken();
  return idToken;
}

function App() {
  const shopify = useAppBridge();
  const api = useApi();

  const [page, setPage] = useState('main');

  const onConnect = useCallback(() => {
  }, [setPage]);

  // TODO: move it to some component AppInstaller
  useEffect(() => {
    fetchIdToken(shopify)
      .then((idToken: string) => api.callInstalledApi(idToken));
  }, [api, shopify]);

  return (
    <ApiProvider>
      <GrapesApiProvider>
        <FirebaseProvider>
          <PolarisProvider>
            <ShopifyAppBridgeProvider>
              <GrapesConnectionStatusProvider>
                <AssignWineModalProvider>
                  <GrapesConnectModalProvider>
                    { page === 'main' ? <MainPage onConnect={onConnect} /> : null } 
                    <Footer />
                  </GrapesConnectModalProvider>
                </AssignWineModalProvider>
              </GrapesConnectionStatusProvider>
            </ShopifyAppBridgeProvider>
          </PolarisProvider>
        </FirebaseProvider>
      </GrapesApiProvider>
    </ApiProvider>
  );
}

export default App;
