import React, { useCallback, useState, useMemo } from 'react';
import { TitleBar } from '@shopify/app-bridge-react';
import LoginGrapes from './LoginGrapes';
import Confirmation from './Confirmation';
import { Box } from '@shopify/polaris';
import SendApplication from './SendApplication';

function GrapesConnect({ onDone }: { onDone: VoidFunction }) {
  const [step, setStep] = useState('login');
  const [grapesAccessToken, setGrapesAccessToken] = useState('');

  const onSignedIn = useCallback((accessToken: string) => {
    setStep('connect');
    setGrapesAccessToken(accessToken);
  }, [setStep]);

  const onCancel = useCallback(() => {
    setStep('login');
  }, [setStep]);

  const onApply = useCallback(() => {
    setStep('apply');
  }, [setStep])

  return (
    <Box padding="200">
      { step === 'login' ? <LoginGrapes onSignedIn={onSignedIn} onCancel={onDone} onApply={onApply} /> : null }
      { step === 'connect' ? <Confirmation accessToken={grapesAccessToken} onConnected={onDone} onCancel={onCancel} /> : null }
      { step === 'apply' ? <SendApplication /> : null }
    </Box>
  );
}

export default GrapesConnect;
