import { Banner, Text } from '@shopify/polaris';

const BannerSyncWarning = ({ onRefresh }: { onRefresh: VoidFunction }) => {
  return (
    <div data-testid="products-sync-alert">
      <Banner action={{ content: 'Refresh', onAction: onRefresh }} tone="warning">
        <Text as="h2" variant="headingSm">
          Synchronization in Progress
        </Text>
        <Text as="p" variant="bodyMd">
          You are currently viewing a partial list of your products. Synchronization is ongoing, and the full list will be available once the process is complete.
          <br/>
          <br/>
          Thank you for your patience.
          <br/>
        </Text>
      </Banner>
    </div>
  );
}

export default BannerSyncWarning;
