import React, { useState, useCallback, createContext, useMemo, ReactNode, useContext } from 'react';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import AssignWineModal from './AssignWineModal';
import { useShopify } from '../ShopifyAppBridgeProvider';

type OnSelectedWineCallback = (productId: string, wineId: string) => void;

interface Api {
  showModal: (productId: string, productName: string, onSelectedWine: OnSelectedWineCallback) => void;
  hideModal: VoidFunction;
}

const nullApi = {
  showModal: (productId: string, productName: string, onSelectedWine: OnSelectedWineCallback) => {},
  hideModal: () => {},
};

const AssignWineModalContext = createContext<Api>(nullApi);

export const useAssignWineModal = () => useContext(AssignWineModalContext);

export const PlainAssignWineModalProvider = (props: { children: ReactNode, api: Partial<Api> }) => {
  const api = { ...nullApi, ...props.api, };

  return (
    <AssignWineModalContext.Provider value={api}>
      {props.children}
    </AssignWineModalContext.Provider>
  );
}

const AssignWineModalProvider = (props: { children: ReactNode }) => {
  const { showModal, hideModal: hideShopifyModal } = useShopify();

  const [focusedProductId, setFocusedProductId] = useState('');
  const [focusedProductName, setFocusedProductName] = useState('');
  const [onSelectedWineCallback, setSelectedWineCallback] = useState<OnSelectedWineCallback>(() => (() =>{}));

  const api = useMemo(() => ({
    showModal: (productId: string, productName: string, onSelectedWine: OnSelectedWineCallback) => {
      setFocusedProductId(productId);
      setFocusedProductName(productName);
      setSelectedWineCallback(() => onSelectedWine);
      showModal('assign-wine');
    },
    hideModal: () => {
      hideShopifyModal('assign-wine');
    }
  }), [showModal, setFocusedProductId, setFocusedProductName, setSelectedWineCallback, hideShopifyModal])

  return (
    <PlainAssignWineModalProvider api={api}>
      {props.children}
      <AssignWineModal
        productId={focusedProductId}
        productName={focusedProductName}
        onSelected={onSelectedWineCallback}
      />
    </PlainAssignWineModalProvider>
  );
}

export default AssignWineModalProvider;
