import React, { useState, createContext, ReactNode, useContext, useMemo } from 'react';
import {
  callConnectionsApiUsingGrapesAccessTokeb,
  callConnectionsApi,
  callConnectApi,
  callDisconnectApi,
  callInstalledApi,
  callProductsApi,
  callGrapesWinesApi,
  callAssignWineApi,
  callFetchAssignedShopifyLocationApi,
  callChangeAssignedShopifyLocationApi,
  type Product,
  type GrapesWine,
} from './api'; 

export interface ApiInterface {
  callConnectionsApiUsingGrapesAccessTokeb: (accessToken: string) => Promise<{ isConnected: boolean }>;
  callConnectionsApi: (idToken: string) => Promise<{ isConnected: boolean }>;
  callConnectApi: (accessToken: string, shopifyToken: string, grapesShopId: string) => Promise<void>;
  callDisconnectApi: (idToken: string) => Promise<void>;
  callInstalledApi: (idToken: string) => Promise<void>;
  callProductsApi: (idToken: string) => Promise<{ inSync: boolean, products: Product[] }>;
  callGrapesWinesApi: (idToken: string, query: string, nextFrom: string) => Promise<{ wines: GrapesWine[], hasMore: boolean, nextFrom: string }>;
  callAssignWineApi: (idToken: string, shopifyProductId: string, grapesWineId: string) => Promise<void>;
  callFetchAssignedShopifyLocationApi: (idToken: string) => Promise<{ locationId: string, noLocationAssigned: boolean }>;
  callChangeAssignedShopifyLocationApi: (idToken: string, shopifyLocationId: string) => Promise<void>;
}

const nullApi: ApiInterface = {
  callConnectionsApiUsingGrapesAccessTokeb: (accessToken: string) => Promise.reject(new Error('null api')),
  callConnectionsApi: (idToken: string) => Promise.reject(new Error('null api')),
  callConnectApi: (accessToken: string, shopifyToken: string, grapesShopId: string) => Promise.reject(new Error('null api')),
  callDisconnectApi: (idToken: string) => Promise.reject(new Error('null api')),
  callInstalledApi: (idToken: string) => Promise.reject(new Error('null api')),
  callProductsApi: (idToken: string) => Promise.reject(new Error('null api')),
  callGrapesWinesApi: (idToken: string, query: string, nextFrom: string) => Promise.reject(new Error('null api')),
  callAssignWineApi: (idToken: string, shopifyProductId: string, grapesWineId: string) => Promise.reject(new Error('null api')),
  callFetchAssignedShopifyLocationApi: (idToken: string) => Promise.reject(new Error('null api')),
  callChangeAssignedShopifyLocationApi: (idToken: string, shopifyLocationId: string) => Promise.reject(new Error('null api')),
}

const realApi = {
  callConnectionsApiUsingGrapesAccessTokeb,
  callConnectionsApi,
  callConnectApi,
  callDisconnectApi,
  callInstalledApi,
  callProductsApi,
  callGrapesWinesApi,
  callAssignWineApi,
  callFetchAssignedShopifyLocationApi,
  callChangeAssignedShopifyLocationApi,
};

const ApiContext = createContext<ApiInterface>(realApi);

export const useApi = () => useContext(ApiContext);

export function FakeApiProvider(props: { children: ReactNode, api: Partial<ApiInterface>}) {
  return (
    <ApiContext.Provider value={{ ...nullApi, ...props.api }}>
      { props.children }
    </ApiContext.Provider>
  );
}
 
function ApiProvider(props: { children: ReactNode }) {
  const api = useMemo(() => realApi, []);

  return (
    <ApiContext.Provider value={api}>
      { props.children }
    </ApiContext.Provider>
  );
}

export default ApiProvider;
